import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Auth, User, authState, createUserWithEmailAndPassword, sendEmailVerification, updateProfile, user } from '@angular/fire/auth';
import { Firestore, collection, collectionSnapshots, doc, serverTimestamp, setDoc } from '@angular/fire/firestore';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import * as Sentry from '@sentry/angular-ivy';
import { Observable, ReplaySubject, catchError, defer, distinctUntilChanged, filter, map, of, switchMap, tap } from 'rxjs';
import { OrgId } from './organisation.service';
import { ContractType } from "./shared/contract";

export type Organisation = {
  id: OrgId;
  name: string;
  contract?: {
    type: ContractType;
  };
  stripeSubscriptionId?: string;
  stripeInvoiceFailed?: Record<string, string>;
}

export type ExtendedOrganisation = Organisation & {
  hasFailedPayment: boolean;
};

export interface OrganisationUser {
  id: string;
  email: string;
  displayString?: string;
  role: 'admin' | 'user';
}

type OrganisationSubject =
  { type: 'pending' } |
  { type: 'success', organisation: Organisation } |
  { type: 'success-missing-organisation' } |
  { type: 'error' };

@Injectable({ providedIn: 'root' })
export class AuthService {
  readonly currentUser: Observable<User | null>;
  readonly currentOrg: Observable<Organisation | null>;
  readonly currentExtendedOrg: Observable<ExtendedOrganisation | null>;

  private _currentOrgReplaySubject = new ReplaySubject<OrganisationSubject>(1);

  constructor(
    private readonly db: Firestore,
    private readonly auth: Auth,
    private readonly http: HttpClient,
    private readonly snackBar: MatSnackBar,
  ) {
    console.log('AuthService#constructor');

    // Set currentOrg from replay subject
    this.currentOrg = this._currentOrgReplaySubject
      .asObservable()
      .pipe(
        filter((res) => res.type !== 'pending'),
        map((res) => res.type === 'success' ? res.organisation : null)
      );

    // Set currentExtendedOrg from currentOrg
    this.currentExtendedOrg = this.currentOrg
      .pipe(
        map((org) => org
          ? ({
            ...org,
            hasFailedPayment: org.stripeInvoiceFailed && org.stripeInvoiceFailed[org.stripeSubscriptionId] && org.stripeInvoiceFailed[org.stripeSubscriptionId].length > 0,
          })
          : null
        )
      );

    // Set currentUser
    this.currentUser = user(this.auth);

    // Set user in sentry
    authState(this.auth)
      .subscribe({
        next: (user) => {
          if (user) {
            Sentry.setUser({ ip_address: '{{auto}}', id: user.uid })
          } else {
            Sentry.setUser(null);
          }
        }
      });

    // Load current organisation on auth change
    authState(this.auth)
      .pipe(
        distinctUntilChanged((previous, current) => previous?.uid === current?.uid),
        switchMap((user) =>
          user === null
            ? of({ type: 'pending' as const })
            : this.http.get<Organisation>('/api/orgs/current')
              .pipe(
                map((organisation) => ({ type: 'success' as const, organisation })),
                catchError((error) => {
                  if (error instanceof HttpErrorResponse && error.status === 404) {
                    return of({ type: 'success-missing-organisation' as const });
                  } else {
                    console.error('AuthService#constructor failed to load organisation from api', error);
                    Sentry.captureException(error);
                    return of({ type: 'error' as const });
                  }
                })
              )
        ),
      )
      .subscribe({
        next: (organisation) => {
          console.log('AuthService#constructor loaded organisation', organisation);
          this._currentOrgReplaySubject.next(organisation);
        },
        error: (error) => {
          console.error('AuthService#constructor unknown failure to load organisation', error);
          Sentry.captureException(error);
        },
      });
  }

  register(name: string, email: string, password: string, url?: string | null): Observable<boolean> {
    return defer(async () => {
      const result = await createUserWithEmailAndPassword(this.auth, email, password);
      const user = result.user;

      updateProfile(user, { displayName: name });
      sendEmailVerification(user, { url: url || `${window.location.origin}/?welcome=1` });

      const userRef = doc(this.db, `users/${user.uid}`);
      try {
        await setDoc(userRef, {
          name,
          createdAt: serverTimestamp(),
        }, { merge: true });
      } catch (err) {
        console.error(`Failed to update user ref ${userRef}: ${err}`);
      }

      return true;
    });
  }

  setCurrentOrganisation(orgId: string): Observable<Organisation> {
    console.log(`AuthService#setCurrentOrganisation(${orgId})`);
    return this.http.post<Organisation>('/api/orgs/current', { orgId })
      .pipe(
        tap((organisation) => {
          console.log(`AuthService#setCurrentOrganisation(${orgId}) tapped`, organisation);
          this._currentOrgReplaySubject.next({ type: 'success', organisation });
        })
      );
  }

  fetchOrganisationUsers(): Observable<OrganisationUser[]> {
    return this.currentOrg
      .pipe(
        switchMap(org => this._fetchOrganisationUsers(org)),
        map(users =>
          users.map(user => {
            user.displayString = user.email || `unknown (${user.id})`;
            return user;
          })
        )
      );
  }

  canActivateCurrentOrganisation(
    router: Router,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    return this._currentOrgReplaySubject
      .asObservable()
      .pipe(
        filter((organisation) =>
          organisation.type === 'success' ||
          organisation.type === 'success-missing-organisation' ||
          organisation.type === 'error'
        ),
        map((organisation) => {
          switch (organisation.type) {
            case 'error':
              this.snackBar.open('Failed to load, please try to reload page', 'Dismiss');
              return false;
            case 'success-missing-organisation':
              return router.createUrlTree(
                ['/', 'event', 'create-organisation'],
                { queryParams: { next: state.url ?? undefined } }
              );
            case 'success':
              return true;
          }
        })
      );
  }

  private _fetchOrganisationUsers(
    org: Organisation
  ): Observable<OrganisationUser[]> {
    if (org) {
      return collectionSnapshots(
        collection(this.db, 'orgs', org.id, 'users')
      )
        .pipe(
          map(documentChangeActions => {
            return documentChangeActions.map(u => {
              const user: OrganisationUser = {
                id: u.id,
                email: u.get('email'),
                role: u.get('role'),
              };
              return user;
            });
          })
        );
    }
    return of([]);
  }
}
